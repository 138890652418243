import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastsManager } from 'ng6-toastr';
import { ViewContainerRef } from "@angular/core";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  public loading = false;
  public form: FormGroup;

  constructor(fb: FormBuilder, public toastr: ToastsManager, vcr: ViewContainerRef, private api: ApiService, private currentRoute: ActivatedRoute, private router: Router) {
    this.toastr.setRootViewContainerRef(vcr);

    this.form = fb.group({
      'userEmail': ['', Validators.compose([Validators.required, Validators.email])] 
    });
  }

  ngOnInit(): void {
  }

  generateSalesCode(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  resetp() {
    this.loading = true;

    const data = {
      email: this.form.controls['userEmail'].value,
      password: this.generateSalesCode(10)
    };


    this.api.resetPassword(data).subscribe(
      (response) => {
        this.loading = false;
        this.router.navigate(['login']).then(() => {
          this.toastr.success('You have successfully reset your password. Please look out for an email from us with more details on how to login.', 'Success');
        });

      },
      (error) => {
        console.log(error);
        this.toastr.warning('Something went wrong while trying to reset your password.', 'Error');
        this.loading = false;
      }
    );
  }

}
