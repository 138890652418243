import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from './modal/modal.service';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { TermsComponent } from './terms/terms.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ToastModule } from 'ng6-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisteredComponent } from './registered/registered.component';
import { DataTablesModule } from "angular-datatables";

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    LoginComponent,
    RegisterComponent,
    UserListComponent,
    UserDetailComponent,
    TermsComponent,
    ForgotComponent,
    RegisteredComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastModule.forRoot(),
    NgxLoadingModule.forRoot({})
  ],
  providers: [ ModalService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
