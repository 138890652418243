
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="brand-wrapper" align="center">
      <img src="assets/images/logo.png" alt="logo" class="logo">
    </div>
    <div class="card login-card">
      <div class="row no-gutters">

        <div class="col-md-12">
          <div class="card-body" style="padding-top: 20px;">

            <p class="login-card-description" style="text-align: center;">You have successfully registered as a Brand Ambassador!</p>

            <p>Thank you for registering as a Brand Ambassador.</p>
            <p>Your unique sales code is: <b>{{ salesCode }}</b></p>
            <p>You should have received an email with more details on how to use this code. You can also save a bookmark to this page for future reference.</p>
            <p>If you have any questions please get in touch with us at info@supportuslocals.co.za</p>


            <nav class="login-card-footer-nav" style="text-align: center;">
              <a href="/terms">Terms of use & Privacy policy</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</main>
<script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>

