import { Component, OnDestroy, OnInit, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastsManager } from 'ng6-toastr';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtInstance: Promise<DataTables.Api>;
  public userName = '';
  public loading = false;
  dtOptions: any = {};
  public ambassadors: any = [];

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(public toastr: ToastsManager, vcr: ViewContainerRef, private api: ApiService, private router: Router) {
    this.toastr.setRootViewContainerRef(vcr);

    if (JSON.parse(localStorage.getItem('UserInfo'))) {
      this.userName = JSON.parse(localStorage.getItem('UserInfo')).userName;
      if (JSON.parse(localStorage.getItem('UserInfo')).userType != 'Administrator') {
        this.router.navigate(['login']).then(() => {
          this.toastr.error('You do not have permission to view that page!', 'Permission Error');
        });
      }
    }
  }

  ngOnInit(): void {
    this.loading = true;
   // this.dtTrigger.subscribe();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: true,
      dom: "Bfrtip",
      buttons: [{
        extend: "copy",
        className: "btn-dark"
      },
      {
        extend: "csv",
        className: "btn-dark"
      },
      {
        extend: "excelHtml5",
        className: "btn-dark"
      }],
      responsive: true,
     // data: this.ambassadors,
      retrieve: true,
      columnDefs: [
        {
          targets: [5, 6, 7, 8, 9, 10, 11],
          visible: false
        },
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 2, targets: -1 }
        ]
      //columns: [
      //  {
      //    data: "userName",
      //    defaultContent: ""
      //  },
      //  {
      //    data: "userEmail",
      //    defaultContent: ""
      //  },
      //  {
      //    data: "userStatus",
      //    defaultContent: ""
      //  },
      //  {
      //    data: "salesCode",
      //    defaultContent: ""
      //  },
      //  {
      //    data: "telephone",
      //    defaultContent: ""
      //  },
      //  {
      //    data: "address",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: "businessName",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: "bankAccountHolder",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: "bankAccountNumber",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: "bankName",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: "bankAccountType",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: "userType",
      //    defaultContent: "",
      //    visible: false
      //  },
      //  {
      //    data: null,
      //    "render": function (data, type, full, meta) {
      //      return '<a class="btn btn-light" href="user-detail/' + full.userGuid + '">View</a>';
      //    }
      //  }
      //]
    };


    this.api.users().subscribe(
      (response) => {
        this.ambassadors = response;

        //for (var i = 0; i < this.ambassadors.length; i++) {
        //  console.log(uuidStringify(this.ambassadors[i]['userGuid']));
        //}

        this.dtTrigger.next(); 
        
          this.loading = false;
      },

      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong during the list retrieval process. Please try again. ' + error, 'Error');
        this.loading = false;
      }
    );

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    //this.dtTrigger.next();    
  }

}
