import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';

import 'rxjs/Rx';
import { Observable, Subject, throwError, Observer } from 'rxjs';

import { environment } from '../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  constructor(private http: HttpClient) {
  }

  apiUrl() {
    return environment.apiUrl;
  }

  private getHeaders() {    
    if (localStorage.getItem('jwtToken')) {
      const jwt_token = localStorage.getItem('jwtToken');
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json').set('X-Access-Token', jwt_token);

      return headers;
    } else {
      console.log('JWT Token not set!');
    }
    return this.headers;
  }

  register(data) {
    return this.http.post(environment.apiUrl + 'register', data, { headers: this.headers })
      .map((response: Response) => {
        return response;
      }).catch((error: Response) => {
        return throwError(error);
      });
  }

  login(data) {
    return this.http.post(environment.apiUrl + 'login', data, { headers: this.headers })
      .map((response: Response) => {
        return response;
      }).catch((error: Response) => {
        return throwError(error);
      });
  }

  users() {
    return this.http.get(environment.apiUrl + 'users', { headers: this.getHeaders() })
      .map((response: Response) => {
        return response;
      }).catch((error: Response) => {
        return throwError(error);
      });
  }


  getUser(userGuid) {
    return this.http.get(environment.apiUrl + 'users/' + userGuid, { headers: this.getHeaders() })
      .map((response: Response) => {
        return response;
      }).catch((error: Response) => {
        return throwError(error);
      });
  }

  saveUser(data) {
    return this.http.post(environment.apiUrl + 'users', data, { headers: this.getHeaders() })
      .map((response: Response) => {
        return response;
      }).catch((error: Response) => {
        return throwError(error);
      });
  }

  resetPassword(data) {
    return this.http.post(environment.apiUrl + 'resetpassword', data, { headers: this.headers })
      .map((response: Response) => {
        return response;
      }).catch((error: Response) => {
        return throwError(error);
      });
  }

}
