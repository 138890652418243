import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { ToastsManager } from 'ng6-toastr';
import { ViewContainerRef } from "@angular/core";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public loading = false;
  public form: FormGroup;

  constructor(public router: Router, fb: FormBuilder, public toastr: ToastsManager, vcr: ViewContainerRef, private api: ApiService) {
    this.toastr.setRootViewContainerRef(vcr);

    this.form = fb.group({
      'userName': ['', Validators.compose([Validators.required])],
      'userEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'telephone': ['', Validators.required],
      'address': ['', Validators.required],
      'instagram': ['', ],
      'bankAccountHolder': ['', ],
      'bankAccountNumber': ['', ],
      'bankName': ['', ],
      'bankAccountType': ['', ]
    });


  }

  ngOnInit(): void {
  }

   generateSalesCode(length) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  register() {
    this.loading = true;
    //this.loading = false;
    //this.router.navigate(['registered/GDKSKJ']).then(() => {
    //  this.toastr.success('Welcome as a Support Us Locals Brand Ambassador!', 'Welcome');
    //});

    const user = {
      userName: this.form.controls['userName'].value,
      userEmail: this.form.controls['userEmail'].value,
      telephone: this.form.controls['telephone'].value,
      address: this.form.controls['address'].value,
      salesCode: 'SUL' + this.generateSalesCode(4),
      instagram: this.form.controls['instagram'].value,
      bankAccountHolder: this.form.controls['bankAccountHolder'].value,
      bankAccountNumber: this.form.controls['bankAccountNumber'].value,
      bankName: this.form.controls['bankName'].value,
      bankAccountType: this.form.controls['bankAccountType'].value,
      userType: 'Brand Ambassador',
      password: this.generateSalesCode(10),
      userStatus: 'Active'
    }

    this.api.register(user)
      .subscribe(
        (response) => {
          console.log(response);
          //console.log(JSON.stringify(response.json()));

          if (response['userEmail'] == "userexist") {
            this.toastr.warning('A user with this email address already exist.', 'User already exist');
            this.loading = false;
          } else if (response['userEmail'] == this.form.controls['userEmail'].value) {
            this.loading = false;

            this.router.navigate(['registered/' + response['salesCode']]).then(() => {
              this.toastr.success('Welcome as a Support Us Locals Brand Ambassador ' + response['userName'] + '!', 'Welcome');
            });

          } else {
            this.toastr.error('Something went wrong during the registration process. Please try again..', 'Error');
            this.loading = false;
          }

        },

        (error) => {
          console.log(error);
          this.toastr.error('An error occured during registration. Please try again later', 'Error');
          this.loading = false;
        }
      );
  
  }
}
