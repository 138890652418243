<ngx-loading [show]="loading"></ngx-loading>
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container">
      <div class="card login-card">
        <div class="row no-gutters">
          <div class="col-md-5">
            <img src="assets/images/login.jpg" alt="login" class="login-card-img">
          </div>
          <div class="col-md-7">
            <div class="card-body">
              <div class="brand-wrapper">
                <img src="assets/images/logo.png" alt="logo" class="logo">
              </div>
              <p class="login-card-description">Sign into your account</p>
              <form role="form" name="form1" [formGroup]="form" (ngSubmit)="login()">
                <div class="form-group">
                  <label for="email" class="sr-only">Email</label>
                  <input type="email" name="email" id="email" class="form-control" placeholder="Email address" formControlName="txtUser">
                  <span class="error" *ngIf="form.get('txtUser').hasError('required') && form.get('txtUser').touched">* Please enter a valid Email Address</span>
                </div>
                <div class="form-group mb-4">
                  <label for="password" class="sr-only">Password</label>
                  <input type="password" name="password" id="password" class="form-control" placeholder="Password" formControlName="txtPassword">
                  <span class="error" *ngIf="form.get('txtPassword').hasError('required') && form.get('txtPassword').touched">* Please enter a valid password<br /></span>
                </div>
                <!--<input name="login" id="login" class="btn btn-block login-btn mb-4" type="button" value="Login">-->
                <button type="submit" class="btn btn-block login-btn mb-4" [disabled]="form.invalid"> Login </button>
              </form>
              <a href="/forgot" class="forgot-password-link">Forgot password?</a>
              <p class="login-card-footer-text">Want to be a Brand Ambassador? <a href="/register" class="text-reset">Register here</a></p>
              <nav class="login-card-footer-nav">
                <a href="/terms">Terms of use & Privacy policy</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>

