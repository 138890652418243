<ngx-loading [show]="loading"></ngx-loading>
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="brand-wrapper" align="center">
      <img src="assets/images/logo.png" alt="logo" class="logo">
    </div>
    <div class="card login-card">
      <div class="row no-gutters">

        <div class="col-md-12">
          <div class="card-body" style="padding-top: 20px;">
       
            <p class="login-card-description" style="text-align: center;">Register as a Brand Ambassador</p>
            <form role="form" name="form1" [formGroup]="form" (ngSubmit)="register()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="userName" class="sr-only">Name &amp; Surname</label>
                    <input type="text" name="userName" id="userName" class="form-control" placeholder="Name & Surname" formControlName="userName">
                    <span class="error" *ngIf="form.get('userName').hasError('required') && form.get('userName').touched">* Please enter a Name &amp; Surname</span>
                  </div>
                  <div class="form-group">
                    <label for="userEmail" class="sr-only">Email</label>
                    <input type="email" name="userEmail" id="userEmail" class="form-control" placeholder="Email address" formControlName="userEmail">
                    <span class="error" *ngIf="(form.get('userEmail').hasError('required') || form.get('userEmail').hasError('email')) && form.get('userEmail').touched">* Please enter a valid Email Address</span>
                  </div>
                  <div class="form-group mb-4">
                    <label for="telephone" class="sr-only">Telephone Number</label>
                    <input type="tel" name="telephone" id="telephone" class="form-control" placeholder="Telephone Number" formControlName="telephone">
                    <span class="error" *ngIf="form.get('telephone').hasError('required') && form.get('telephone').touched">* Please enter a valid telephone number<br /></span>
                  </div>
                  <div class="form-group">
                    <label for="address" class="sr-only">Address</label>
                    <textarea name="address" id="address" class="form-control" placeholder="Address" formControlName="address" rows="6" style="line-height: 1;"></textarea>
                    <span class="error" *ngIf="form.get('address').hasError('required') && form.get('address').touched">* Please enter a valid address<br /></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="businessName" class="sr-only">Instagram Link</label>
                    <input type="text" name="instagram" id="instagram" class="form-control" placeholder="Instagram Link" formControlName="instagram">
                  </div>
                  <div class="form-group mb-4">
                    <label for="bankAccountHolder" class="sr-only">Bank Account Holder</label>
                    <input type="text" name="bankAccountHolder" id="bankAccountHolder" class="form-control" placeholder="Bank Account Holder" formControlName="bankAccountHolder">
                  </div>
                  <div class="form-group mb-4">
                    <label for="bankAccountNumber" class="sr-only">Bank Account Number</label>
                    <input type="text" name="bankAccountNumber" id="bankAccountNumber" class="form-control" placeholder="Bank Account Number" formControlName="bankAccountNumber">
                  </div>
                  <div class="form-group mb-4">
                    <label for="bankName" class="sr-only">Bank Name</label>
                    <input type="text" name="bankName" id="bankName" class="form-control" placeholder="Bank Name" formControlName="bankName">
                  </div>
                  <div class="form-group mb-4">
                    <label for="bankAccountType" class="sr-only">Bank Account Type</label>
                    <select id="bankAccountType" name="bankAccountType" class="form-control" formControlName="bankAccountType" autocomplete="off" style="min-height: 50px;">
                      <option value="" disabled selected="selected" autocomplete="off">Select Bank Account Type:</option>
                      <option value="Cheque">Cheque / Current</option>
                      <option value="Savings">Savings</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <button type="submit" class="btn btn-block login-btn mb-4" [disabled]="form.invalid"> Register </button>
            </form>
            
            
            <nav class="login-card-footer-nav" style="text-align: center;">
              <a href="/terms">Terms of use & Privacy policy</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: right;">
      <a href="/login" class="text-reset">Admin Login</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  </div>
</main>
<script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>

