<ngx-loading [show]="loading"></ngx-loading>
<!-- Page Wrapper -->
<div id="wrapper">
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="user-list">
      <div class="sidebar-brand-icon">
        <img src="assets/images/africa.png" style="height: 40px;" />
      </div>
      <div class="sidebar-brand-text mx-3"><img src="assets/images/name.png" style="height: 40px;" /></div>
    </a>
    <!-- Divider -->
    <hr class="sidebar-divider my-0">
    <!-- Nav Item - Dashboard -->
    <!-- Nav Item - Tables -->
    <li class="nav-item active">
      <a class="nav-link" href="user-list">
        <i class="fas fa-fw fa-table"></i>
        <span>Brand Ambassadors</span>
      </a>
    </li>

    
    <!--<li class="nav-item">
      <a class="nav-link" href="register">
        <i class="fas fa-user fa-sm fa-fw mr-2"></i>
        <span>Register New User</span>
      </a>
    </li>-->

    <li class="nav-item">
      <a class="nav-link" href="login">
        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
        <span>Logout</span>
      </a>
    </li>


    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
  <!-- End of Sidebar -->
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Sidebar Toggle (Topbar) -->
        <form class="form-inline">
          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>
        </form>
       
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
          
          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ userName }}</span>
              <img class="img-profile rounded-circle"
                   src="assets/images/SULSA-Africa.png">
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                 aria-labelledby="userDropdown">
              <a class="dropdown-item" href="user-detail">
                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                My Profile
              </a>             
              <a class="dropdown-item" href="login" data-toggle="modal" data-target="#logoutModal">
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <!-- End of Topbar -->
      <!-- Begin Page Content -->
      <div class="container-fluid">
        <!-- Page Heading -->
        <!--<h1 class="h3 mb-2 text-gray-800">Tables</h1>
        <p class="mb-4">
          DataTables is a third party plugin that is used to generate the demo table below.
          For more information about DataTables, please visit the <a target="_blank"
                                                                     href="https://datatables.net">official DataTables documentation</a>.
        </p>-->
        <!-- DataTales Example -->
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-dark">Brand Ambassadors</h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered row-border hover responsive nowrap" id="dataTable" width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Sales Code</th>
                    <th>Telephone</th>
                    <th>Address</th>
                    <th>Instagram Link</th>
                    <th>Bank Account Holder</th>
                    <th>Bank Account Number</th>
                    <th>Bank Name</th>
                    <th>Bank Account Type</th>
                    <th>User Type</th>
                    <th>View</th>
                  </tr>
                </thead>                
                <tbody>
                  <tr *ngFor="let ambassador of ambassadors">
                    <td>{{ ambassador.userName }}</td>
                    <td>{{ ambassador.userEmail }}</td>
                    <td>{{ ambassador.userStatus }}</td>
                    <td>{{ ambassador.salesCode }}</td>
                    <td>{{ ambassador.telephone }}</td>
                    <td class="none">{{ ambassador.address }}</td>
                    <td>{{ ambassador.instagram }}</td>
                    <td>{{ ambassador.bankAccountHolder }}</td>
                    <td>{{ ambassador.bankAccountNumber }}</td>
                    <td>{{ ambassador.bankName }}</td>
                    <td>{{ ambassador.bankAccountType }}</td>
                    <td>{{ ambassador.userType }}</td>
                    <td><a class="btn btn-dark" href="user-detail/{{ ambassador.userGuid }}">View</a></td>
                  </tr>                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->
    <!-- Footer -->
    <footer class="sticky-footer bg-white">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>Copyright &copy; Support Us Locals</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->
<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>
<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">—</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <a class="btn btn-primary" href="login">Logout</a>
      </div>
    </div>
  </div>
</div>
