import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { ToastsManager } from 'ng6-toastr';
import { ViewContainerRef } from "@angular/core";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loading = false;
  public form: FormGroup;

  constructor(public router: Router, fb: FormBuilder, public toastr: ToastsManager, vcr: ViewContainerRef, private api: ApiService) {
    this.toastr.setRootViewContainerRef(vcr);

    this.form = fb.group({
      'txtUser': ['', Validators.compose([Validators.required])],
      'txtPassword': ['', Validators.compose([Validators.required])]
    });

    localStorage.clear();
  }

  ngOnInit(): void {
  }

  login() {
    this.loading = true;

    const loginData = {
      'userEmail': this.form.controls['txtUser'].value,
      'password': this.form.controls['txtPassword'].value
    };

    this.api.login(loginData).subscribe(
      (response) => {
        
        localStorage.setItem('isSULLoggedin', 'true');

        localStorage.setItem('UserInfo', JSON.stringify(response['data']));
        localStorage.setItem('jwtToken', response['auth']['token']);
        this.loading = false;
        this.router.navigate(['user-list']).then(() => {
          this.toastr.success('Welcome to the SULSA Ambassador Portal', 'Welcome');
        });
        
      },
      (error) => {

        this.toastr.warning('Please provide valid login credentials', 'Invalid credentials');
        this.loading = false;
      }
    );

   
  }
}
