<ngx-loading [show]="loading"></ngx-loading>
<!-- Page Wrapper -->
<div id="wrapper">
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="user-list">
      <div class="sidebar-brand-icon">
        <img src="assets/images/africa.png" style="height: 40px;" />
      </div>
      <div class="sidebar-brand-text mx-3"><img src="assets/images/name.png" style="height: 40px;" /></div>
    </a>
    <!-- Divider -->
    <hr class="sidebar-divider my-0">
    <!-- Nav Item - Dashboard -->
    <!-- Nav Item - Tables -->
    <li class="nav-item">
      <a class="nav-link" href="user-list">
        <i class="fas fa-fw fa-table"></i>
        <span>Brand Ambassadors</span>
      </a>
    </li>


    <!--<li class="nav-item">
      <a class="nav-link" href="register">
        <i class="fas fa-user fa-sm fa-fw mr-2"></i>
        <span>Register New User</span>
      </a>
    </li>-->

    <li class="nav-item">
      <a class="nav-link" href="login">
        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
        <span>Logout</span>
      </a>
    </li>


    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
  <!-- End of Sidebar -->
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Sidebar Toggle (Topbar) -->
        <form class="form-inline">
          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>
        </form>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ userName }}</span>
              <img class="img-profile rounded-circle"
                   src="assets/images/SULSA-Africa.png">
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                 aria-labelledby="userDropdown">
              <a class="dropdown-item" href="user-detail">
                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                My Profile
              </a>
              <a class="dropdown-item" href="login" data-toggle="modal" data-target="#logoutModal">
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <!-- End of Topbar -->
      <!-- Begin Page Content -->
      <div class="container-fluid">

        <!-- DataTales Example -->
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-dark">Viewing: {{ ambassadorName }} - {{ ambassadorCode }} </h6>
          </div>
          <div class="card-body">

            <form role="form" name="form1" [formGroup]="form" (ngSubmit)="register()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="userName" class="form-label">Name &amp; Surname</label>
                    <input type="text" name="userName" id="userName" class="form-control" placeholder="Name & Surname" formControlName="userName">
                    <span class="error" *ngIf="form.get('userName').hasError('required') && form.get('userName').touched">* Please enter a Name &amp; Surname</span>
                  </div>
                  <div class="form-group">
                    <label for="userEmail" class="form-label">Email</label>
                    <input type="email" name="userEmail" id="userEmail" class="form-control" placeholder="Email address" formControlName="userEmail">
                    <span class="error" *ngIf="(form.get('userEmail').hasError('required') || form.get('userEmail').hasError('email')) && form.get('userEmail').touched">* Please enter a valid Email Address</span>
                  </div>
                  <div class="form-group">
                    <label for="telephone" class="form-label">Telephone Number</label>
                    <input type="tel" name="telephone" id="telephone" class="form-control" placeholder="Telephone Number" formControlName="telephone">
                    <span class="error" *ngIf="form.get('telephone').hasError('required') && form.get('telephone').touched">* Please enter a valid telephone number<br /></span>
                  </div>
                  <div class="form-group">
                    <label for="address" class="form-label">Address</label>
                    <textarea name="address" id="address" class="form-control" placeholder="Address" formControlName="address" rows="6" style="line-height: 1;"></textarea>
                    <span class="error" *ngIf="form.get('address').hasError('required') && form.get('address').touched">* Please enter a valid address<br /></span>
                  </div>
                  <div class="form-group">
                    <label for="salesCode" class="form-label">Sales Code</label>
                    <input type="text" name="salesCode" id="salesCode" class="form-control" placeholder="Sales Code" formControlName="salesCode" disabled="disabled">
                    <span class="error" *ngIf="form.get('salesCode').hasError('required') && form.get('salesCode').touched">* Please enter a valid sales code<br /></span>
                  </div>
                  <div class="form-group">
                    <label for="userStatus" class="form-label">User Status</label>
                    <select id="userStatus" name="userStatus" class="form-control" formControlName="userStatus" autocomplete="off" style="min-height: 50px;">
                      <option value="" disabled selected="selected" autocomplete="off">Select User Status:</option>
                      <option value="Active">Active</option>
                      <option value="In-Active">In-Active</option>
                      <option value="Fraud">Fraud</option>
                    </select>
                    <span class="error" *ngIf="form.get('userStatus').hasError('required') && form.get('userStatus').touched">* Please enter a valid User Status<br /></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="instagram" class="form-label">Instagram Link</label>
                    <input type="text" name="instagram" id="instagram" class="form-control" placeholder="Instagram Link" formControlName="instagram">
                  </div>
                  <div class="form-group">
                    <label for="bankHolder" class="form-label">Bank Account Holder</label>
                    <input type="text" name="bankHolder" id="bankHolder" class="form-control" placeholder="Bank Account Holder" formControlName="bankHolder">
                  </div>
                  <div class="form-group">
                    <label for="bankAccount" class="form-label">Bank Account Number</label>
                    <input type="text" name="bankAccount" id="bankAccount" class="form-control" placeholder="Bank Account Number" formControlName="bankAccount">
                  </div>
                  <div class="form-group">
                    <label for="bankName" class="form-label">Bank Name</label>
                    <input type="text" name="bankName" id="bankName" class="form-control" placeholder="Bank Name" formControlName="bankName">
                  </div>
                  <div class="form-group">
                    <label for="bankType" class="form-label">Bank Account Type</label>
                    <select id="bankType" name="bankType" class="form-control" formControlName="bankType" autocomplete="off" style="min-height: 50px;">
                      <option value="" disabled selected="selected" autocomplete="off">Select Bank Account Type:</option>
                      <option value="Cheque">Cheque / Current</option>
                      <option value="Savings">Savings</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="userType" class="form-label">User Type</label>
                    <select id="userType" name="userType" class="form-control" formControlName="userType" autocomplete="off" style="min-height: 50px;">
                      <option value="" disabled selected="selected" autocomplete="off">Select User Type:</option>
                      <option value="Brand Ambassador">Brand Ambassador</option>
                      <option value="Administrator">Administrator</option>
                    </select>
                    <span class="error" *ngIf="form.get('userType').hasError('required') && form.get('userType').touched">* Please enter a valid User Type<br /></span>
                  </div>

                  <div class="form-group" *ngIf="form.get('userType').value == 'Administrator'">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" name="password" id="password" class="form-control" placeholder="Password" formControlName="password">
                    <span class="error" *ngIf="form.get('password').hasError('required') && form.get('password').touched">* Please enter a valid password<br /></span>
                  </div>

                  <div class="form-group" *ngIf="form.get('userType').value == 'Administrator'">
                    <label for="confirmPassword" class="form-label">Confirm Password</label>
                    <input type="password" name="confirmPassword" id="confirmPassword" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword">
                    <span class="error" *ngIf="form.get('confirmPassword').hasError('required') && form.get('confirmPassword').touched">* Please enter a valid confirmed password<br /></span>
                  </div>

                </div>
              </div>

              <button type="submit" class="btn btn-block login-btn mb-4 btn-dark"> Save User </button>
            </form>

          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->
    <!-- Footer -->
    <footer class="sticky-footer bg-white">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>Copyright &copy; Support Us Locals</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->
<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>
<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">—</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <a class="btn btn-primary" href="login">Logout</a>
      </div>
    </div>
  </div>
</div>
