import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastsManager } from 'ng6-toastr';
import { ViewContainerRef } from "@angular/core";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  public userName = '';
  public myprofile = false;
  public userGuid = '';
  public ambassadorName = '';
  public ambassadorCode = '';
  public loading = false;
  public form: FormGroup;

  constructor(fb: FormBuilder, public toastr: ToastsManager, vcr: ViewContainerRef, private api: ApiService, private currentRoute: ActivatedRoute, private router: Router) {
    if (JSON.parse(localStorage.getItem('UserInfo'))) {
      this.userName = JSON.parse(localStorage.getItem('UserInfo')).userName;
      if (JSON.parse(localStorage.getItem('UserInfo')).userType != 'Administrator') {
        this.router.navigate(['login']).then(() => {
          this.toastr.error('You do not have permission to view that page!', 'Permission Error');
        });
      }
    }

    if (this.currentRoute.snapshot.params['userGuid'] !== undefined) {
      this.userGuid = this.currentRoute.snapshot.params['userGuid'];
      this.myprofile = false;
    }
    else {
      this.userGuid = JSON.parse(localStorage.getItem('UserInfo')).userGuid;
      this.myprofile = true;
    }


    this.toastr.setRootViewContainerRef(vcr);

    this.form = fb.group({
      'userName': ['', Validators.compose([Validators.required])],
      'userEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'telephone': ['', Validators.required],
      'address': ['', Validators.required],
      'instagram': ['',],
      'bankHolder': ['', ],
      'bankAccount': ['', ],
      'bankName': ['', ],
      'bankType': ['', ],
      'salesCode': ['', Validators.required],
      'userType': ['', Validators.required],
      'password': ['', ],
      'confirmPassword': ['', ],
      'userStatus': ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.api.getUser(this.userGuid).subscribe(
      (response) => {
        const theUser = {
          userName: response['userName'],
          userEmail: response['userEmail'],
          telephone: response['telephone'],
          address: response['address'],
          instagram: response['instagram'],
          bankHolder: response['bankAccountHolder'],
          bankAccount: response['bankAccountNumber'],
          bankName: response['bankName'],
          bankType: response['bankAccountType'],
          salesCode: response['salesCode'],
          userType: response['userType'],
          password: null,
          confirmPassword: null,
          userStatus: response['userStatus']
        }
        this.form.setValue(theUser);

        this.ambassadorCode = response['salesCode'];
        this.ambassadorName = response['userName'];
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong while updating the user, please try again. ' + error, 'Error');
        this.loading = false;
      }
    );
  }

  register() {
    this.loading = true;

    if (this.form.controls['password'].value !== this.form.controls['confirmPassword'].value) {
      this.toastr.warning('Passwords do not match', 'Error');
      this.loading = false;
    }
    else {
      const user = {
        userGuid: this.userGuid,
        userName: this.form.controls['userName'].value,
        userEmail: this.form.controls['userEmail'].value,
        telephone: this.form.controls['telephone'].value,
        address: this.form.controls['address'].value,
        instagram: this.form.controls['instagram'].value,
        bankAccountHolder: this.form.controls['bankHolder'].value,
        bankAccountNumber: this.form.controls['bankAccount'].value,
        bankName: this.form.controls['bankName'].value,
        bankAccountType: this.form.controls['bankType'].value,
        salesCode: this.form.controls['salesCode'].value,
        userType: this.form.controls['userType'].value,
        userStatus: this.form.controls['userStatus'].value,
        password: this.form.controls['password'].value
      }

      this.api.saveUser(user)
        .subscribe(
          (response) => {
           
            this.toastr.success('User successfully updated.', 'Success');
            this.loading = false;
          },
          (error) => {
            console.log(error);
            this.toastr.error('An error while saving the user. Please try again later', 'Error');
            this.loading = false;
          }
        );

    
    }

  } 

}
