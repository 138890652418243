import { Component, OnInit } from '@angular/core';
import { ToastsManager } from 'ng6-toastr';
import { ViewContainerRef } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.css']
})
export class RegisteredComponent implements OnInit {
  public salesCode = '';


  constructor(public toastr: ToastsManager, vcr: ViewContainerRef, private currentRoute: ActivatedRoute) {
    this.toastr.setRootViewContainerRef(vcr);

    if (this.currentRoute.snapshot.params['code'] !== undefined) {
      this.salesCode = this.currentRoute.snapshot.params['code'];
    }
  }

  ngOnInit(): void {
  }

}
